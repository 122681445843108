import { useEffect, useState, useRef } from "react";
import ProcessTable from "../../components/process-table/ProcessTable";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./detailStyles";
import { Button, Input, GetRef, message, Modal } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { ReactComponent as TickTrue } from "./icons/tick-true.svg";
import { ReactComponent as TickFalse } from "./icons/tick-false.svg";
import { ReactComponent as EmptyIcon } from "./icons/empty.svg";
import { ReactComponent as CardLoaderIcon } from "../../app/icons/card_loader.svg";
import axios from "../../common/api/axios";
type InputRef = GetRef<typeof Input>;
interface DataType {
  binId: string;
  skuId: string;
  slotAssigned: boolean;
  isDecommissioned: string;
  isLocked: boolean;
  lockedBy: string;
  items: any;
  event: string;
}
interface DetailsType {
  skuId: string;
  quantity: number;
  description: string;
  imageUri: string;
  lowInventoryThreshold: number;
  quantityToTopUp: number;
  storedInCartePlus: boolean;
  productName: string;
  weightInGrams: number;
  barCode: string;
  isActive: boolean;
  vendor: string;
  notes: string;
  inductQuantity: number;
}
interface ConfigType {
  quantityToTopUp: number;
  inductQuantity: number;
}
type DataIndex = keyof DataType;
export default function SkuDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const skuId = location?.state.clickedItem;
  const [inventoryData, setInventoryData] = useState([]);
  const [configData, setConfigData] = useState<ConfigType>();
  const [allDetailsData, setDetailsDataSource] = useState<DetailsType>();
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState<string | undefined>(skuId);
  const [searchData, setSearchData] = useState<string | undefined>(skuId);
  const [messageApi, contextHolder] = message.useMessage();
  const [isImageUrlModalVisible, setIsImageUrlModalVisible] = useState(false);
  const [newImageUrl, setNewImageUrl] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const refreshSkuDetails = async () => {
    if (!allDetailsData?.skuId) return;
    
    try {
      const res = await axios.get(`MasterData/sku/${allDetailsData.skuId}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setDetailsDataSource(res.data);
      }
    } catch (error: any) {
      messageApi.error(error?.response?.data || "Failed to refresh SKU details");
    }
  };


  const updateImageUrl = async () => {
    if (!allDetailsData?.skuId || !newImageUrl) return;

    setIsUpdating(true);
    try {
      const res = await axios.put(`MasterData/sku/${allDetailsData.skuId}/updateImageUrl`,
        JSON.stringify(newImageUrl),
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json-patch+json',
            tenantId: 1,
          },
        }
      );

      if (res.status === 200) {
        messageApi.success("Image URL updated successfully");
        refreshSkuDetails();
        setIsImageUrlModalVisible(false);
        setNewImageUrl("");
      }
    } catch (error: any) {
      messageApi.error(error?.response?.data || "Failed to update image URL");
    } finally {
      setIsUpdating(false);
    }
  };

  const getBinDetailsData = async () => {
    if (searchText) {
      try {
        const res = await axios.get(`MasterData/sku/${searchText}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });
        if (res.status === 200) {
          setDetailsDataSource(res.data);
          //setInventoryData(res.data.items);
          setLoader(false);
          setSearchText("");
          setSearchData(searchText);
        }
      } catch (error: any) {
        setLoader(false);
        if (error?.response?.data) {
          messageApi.open({
            type: "error",
            content: error?.response?.data,
            className: "error",
            style: {
              marginTop: "85vh",
            },
          });
        }
        setSearchText('');
      }
    }
  };
  const getSkuConfigData = async () => {
    if (searchText) {
      try {
        const res = await axios.get(`MasterData/skuConfig/${searchText}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });
        if (res.status === 200) {
          setConfigData(res.data);
        }
      } catch (error: any) {
        if (error?.response?.data) {
          messageApi.open({
            type: "error",
            content: error?.response?.data,
            className: "error",
            style: {
              marginTop: "85vh",
            },
          });
        }
      }
    }
  };
  const getInventoryData = async () => {
    if (searchText) {
      try {
        const res = await axios.post(`BinMaster/binsForSkus/`,
          [searchText],
          {
            headers: {
              Accept: "application/json",
              tenantId: 1,
            },
          });
        if (res.status === 200) {
          if (res.data) {
            setInventoryData(res.data);
            if (res.data.length > 0) {
              getBinData(res.data)
            }
          }
        }
      } catch (error: any) {
        if (error?.response?.data) {
          messageApi.open({
            type: "error",
            content: error?.response?.data,
            className: "error",
            style: {
              marginTop: "85vh",
            },
          });
        }
      }
    }
  };
  const getBinData = async (binId: any) => {
    try {
      const res = await axios.get(`BinMaster/items/${binId[0]}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        const updateInven: any = binId.map((val: any) => {
          return {
            binId: binId[0],
            qty: res.data.length
          }
        });
        setInventoryData(updateInven)
        //setInventoryCnt(res.data.length);
      }
    } catch (error: any) {
      if (error?.response?.data) {
        messageApi.open({
          type: "error",
          content: error?.response?.data,
          className: "error",
          style: {
            marginTop: "85vh",
          },
        });
      }
    }
  };

  useEffect(() => {
    getBinDetailsData();
    getSkuConfigData();
    getInventoryData();
  }, []);

  const Externalcolumns: ColumnsType<DataType> = [
    {
      dataIndex: "binId",
      key: "binId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "binId"
        );
        return (
          <Styles.ArrowContainer>
            Bin ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      render: (text, record) => (
        <Styles.UnderlineText>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.UnderlineText>
      ),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.binId.localeCompare(b.binId),
      defaultSortOrder: 'ascend'
    },
    {
      dataIndex: "qty",
      key: "qty",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Qty</Styles.ArrowContainer>;
      },
    },
  ];
  const Externaldata: any = [
    {
      binId: "TOTEO1010007",
      qty: "5",
    },
    {
      binId: "TOTEO1010017",
      qty: "55",
    },
    {
      binId: "TOTEO1010008",
      qty: "55",
    },
    {
      binId: "TOTEO1010007",
      qty: "5",
    },
    {
      binId: "TOTEO1010017",
      qty: "55",
    },
    {
      binId: "TOTEO1010008",
      qty: "55",
    },
    {
      binId: "TOTEO1010007",
      qty: "5",
    },
    {
      binId: "TOTEO1010017",
      qty: "55",
    },
    {
      binId: "TOTEO1010008",
      qty: "55",
    },
    {
      binId: "TOTEO1010007",
      qty: "5",
    },
    {
      binId: "TOTEO1010017",
      qty: "55",
    },
    {
      binId: "TOTEO1010008",
      qty: "55",
    },
  ];
  const redirectDetails = (clickedItem: string) => {
    navigate(`/bin_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
  };

  return (
    <Styles.Container>
      {/* <Styles.SubHead> */}
      <Sidebar />
      <Styles.BlockContainer>
        {/* </Styles.SubHead> */}
        {loader ? (
          <CardLoaderIcon />
        ) : (
          <Styles.TableContainer>
            <Styles.breadCrumb>
              <Styles.breadText>
                <a href="order_list">Home /</a> SKU /{" "}
                <a href="sku_list">SKU List /</a> SKU ID: {searchData}
              </Styles.breadText>
            </Styles.breadCrumb>
            <Styles.TableHead>
              <Styles.Title>SKU ID</Styles.Title>
              <Styles.searchBox>
                <Input
                  size="large"
                  placeholder="Input Sku ID"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onPressEnter={() => getBinDetailsData()}
                />
              </Styles.searchBox>
              <Styles.exportCSV>
                <Button onClick={() => getBinDetailsData()}>Submit</Button>
              </Styles.exportCSV>
            </Styles.TableHead>
            <Styles.BoxRowFull>
              <Styles.BoxRow>
                <Styles.LeftBox>
                  <Styles.BoxHead>
                    <Styles.Title>
                      SKU Details - {allDetailsData?.skuId}
                    </Styles.Title>
                  </Styles.BoxHead>
                  <Styles.Divider />
                  <Styles.TextStyle>
                    Vendor: {allDetailsData?.vendor}
                  </Styles.TextStyle>
                  <Styles.TextStyle>
                    Quantity: {allDetailsData?.quantity}
                  </Styles.TextStyle>
                  <Styles.TextStyle>
                    Description: {allDetailsData?.description}
                  </Styles.TextStyle>
                  <Styles.TextStyle>
                    Notes: {allDetailsData?.notes}
                  </Styles.TextStyle>
                  <Styles.TextStyle>
                    Low Inventory Threshold:{" "}
                    {allDetailsData?.lowInventoryThreshold}{" "}
                  </Styles.TextStyle>
                  <Styles.TextStyle>
                    Quantity To Top Up: {configData?.quantityToTopUp}{" "}
                  </Styles.TextStyle>
                  <Styles.TextStyle>
                    Induction Quantity: {configData?.inductQuantity}{" "}
                  </Styles.TextStyle>
                  <Styles.TextStyle>Stored In Carte+</Styles.TextStyle>
                  {allDetailsData?.storedInCartePlus ? (
                    <TickTrue />
                  ) : (
                    <TickFalse />
                  )}
                </Styles.LeftBox>
                <Styles.LeftBox className="other">
                  <Styles.BoxHead>
                    <Styles.Title className="card-title">Inventory</Styles.Title>
                  </Styles.BoxHead>
                  <Styles.Divider />
                  <Styles.TableView>
                    <ProcessTable
                      column={Externalcolumns}
                      data={inventoryData}
                      loader={loader}
                    />
                  </Styles.TableView>
                  {inventoryData && inventoryData.length > 0 &&
                    <Styles.pageText>

                      {inventoryData.length} Items

                    </Styles.pageText>
                  }
                </Styles.LeftBox>
              </Styles.BoxRow>

              <Styles.RightBox>
                <Styles.BoxHead>
                  <Styles.Title>SKU Image</Styles.Title>
                  <Button
                    type="primary"
                    onClick={() => setIsImageUrlModalVisible(true)}
                  >
                    Update Image URL
                  </Button>
                </Styles.BoxHead>
                <Styles.Divider />
                {allDetailsData?.imageUri ?
                  <img
                    src={allDetailsData?.imageUri}
                    width={570}
                    height={675}
                    alt="product image"
                  />
                  :
                  <Styles.EmptyImage>
                    <EmptyIcon />
                    <p>No Image</p>
                  </Styles.EmptyImage>
                }
                <Modal
                  title="Update Image URL"
                  open={isImageUrlModalVisible}
                  onOk={updateImageUrl}
                  onCancel={() => {
                    setIsImageUrlModalVisible(false);
                    setNewImageUrl("");
                  }}
                  confirmLoading={isUpdating}
                  okButtonProps={{ disabled: isUpdating }}
                >
                  <Input
                    placeholder="Enter new image URL"
                    value={newImageUrl}
                    onChange={(e) => setNewImageUrl(e.target.value)}
                    disabled={isUpdating}
                  />
                </Modal>
              </Styles.RightBox>
            </Styles.BoxRowFull>
          </Styles.TableContainer>
        )}
        <>{contextHolder}</>
      </Styles.BlockContainer>
    </Styles.Container>
  );
}
